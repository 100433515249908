@import "../_settings.scss";

.certificate {
    width: 686px;
    min-height: 295px;
    position: relative;
    background: #fff;
}

.certifiacte-custom {
    width: 711px;
    min-height: 335px;
    margin: 20px;
    position: relative;
    background: url(../../images/giftcertificate/giftcertificatbg-new.png) no-repeat;
    background-size: 100% 100%;
    border-radius: 25px;
    box-shadow: 0px 18px 8px -4px rgba(34, 60, 80, 0.2);

    &__persons {
        display: flex;
        padding-left: 30px;
        margin-top: 15px;
        max-width: 400px;
        flex-wrap: wrap;
    }

    &__person-to span:first-child,
    &__person-from span:first-child {
        font-size: 12px;
        /*font-family: "Times New Roman";*/
        font-family: "Circe",sans-serif;
        color: rgba( 83, 88, 93, 0.6 );
        text-align: left;
    }

    &__person-to span:last-child,
    &__person-from span:last-child {
        font-size: 12px;
        /*font-family: "ClearSans";*/
        font-family: "Circe",sans-serif;
        color: rgb( 83, 88, 93 );
        margin-left: 12px;
    }

    &__person-to {
        margin-right: 30px;
        margin-bottom: 0;
        display: flex;
        flex-wrap: nowrap;
    }

    &__person-from {
        margin-bottom: 0;
        display: flex;
        flex-wrap: nowrap;
    }

    &__cert-price {
        position: absolute;
        right: 110px;
        width: 100px;
        text-align: center;
        bottom: 12%;

        span {
            font-size: 24px;
            /*font-family: "ClearSans";*/
            font-family: "Circe",sans-serif;
            color: rgb( 255, 255, 255 );
            font-weight: bold;
        }
    }

    &__message {
        margin-top: 12px;
        padding-left: 30px;
        max-width: 400px;
        line-height: 1;

        span {
            font-size: 12px;
            /*font-family: "ClearSans";*/
            font-family: "Circe",sans-serif;
            color: rgb( 83, 88, 93 );
            line-height: 1;
        }
    }

    &__use {
        padding-left: 30px;
        margin-top: 12px;
        /*font-family: "ClearSans";*/
        font-family: "Circe",sans-serif;
        color: rgba( 83, 88, 93, 0.6 );
        max-width: 400px;
        line-height: 1;
        padding-bottom: 30px;
    }

    &__code-wrap {
        font-size: 14px;
        /*font-family: "ClearSans";*/
        font-family: "Circe",sans-serif;
        color: rgb( 255, 255, 255 );
        display: flex;
        transform: rotate( -90deg);
        max-width: 500px;
        position: absolute;
        bottom: 80px;
        right: -30px;
    }

    &__code {
        margin-left: 5px;
    }
}

#giftcertificatePreview .modal-content {
    background: #e6e6e6;
}

.certifiacte-custom .header {
    padding: 30px;
    height: auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
}

.certifiacte-custom .descr {
    padding-left: 30px;
}

.certifiacte-custom .descr p:first-child {
    font-size: 60px;
    font-family: "Circe",sans-serif;
    color: rgb( 255, 41, 41 );
    margin: 0;
    line-height: 1.1;
}

.certifiacte-custom .descr p:last-child {
    font-size: 36px;
    font-family: "Circe",sans-serif;
    color: rgb( 83, 88, 93 );
    margin: 0;
    line-height: 1.1;
}

.certificate .header {
    padding: 8px 0 8px 175px;
    font-family: Tahoma;
    border-bottom: 3px #00539a solid;
    margin-bottom: 2px;
}

.certificate .logo-wrap {
    float: left;
}

.certificate .logo-wrap img {
    max-width: 270px;
}

.certificate .code-wrap {
    float: right;
    width: 200px;
}

.certificate .code-wrap .text {
    color: #b80700;
    padding-bottom: 10px;
}

.certificate .code-wrap .code {
    font-size: 18px;
    color: #393939;
}

.certificate .section {
    background: #e7f5ff url(../../images/giftcertificate/bg_certificate.jpg) no-repeat scroll center bottom;
    position: relative;
    /* font-family: "Times New Roman";*/
    font-family: "Circe",sans-serif;
    color: #00539a;
    padding: 10px 0;
    min-height: 210px;
    -webkit-print-color-adjust: exact;
}

.certificate .section .cert-data {
    margin-left: 215px;
    padding-bottom: 25px;
}

.certificate .section .cert-data .name {
    padding-bottom: 20px;
}

.certificate .section .use {
    font-family: Tahoma;
    left: 214px;
    bottom: 5px;
    position: absolute;
    color: #000;
}

.certificate .section .persons {
    font-size: 17px;
    /*font-family: Times New Roman;*/
    font-family: "Circe",sans-serif;
    font-style: italic;
    padding-bottom: 15px;
}

.certificate .section .persons .person-to, .certificate .section .persons .person-from {
    float: left;
    margin-right: 40px;
    padding-top: 5px;
}

.certificate .section .persons .person-to img, .certificate .section .persons .person-from img {
    margin-right: 8px;
}

.certificate .section .persons .person-to img {
    vertical-align: middle;
}

.certificate .section .persons .person-from img {
    vertical-align: middle;
}

.certificate .section .message {
    width: 250px;
    background: url(../../images/giftcertificate/line.png) repeat-y scroll left 30px;
    font-size: 16px;
    font-style: italic;
    /*font-family: Times New Roman;*/
    font-family: "Circe",sans-serif;
    line-height: 31px;
    position: relative;
    float: left;
    -webkit-print-color-adjust: exact;
}

.certificate .section .site-use {
    color: #000;
    font-weight: bold;
}

.certificate .section .cert-price {
    font-family: Tahoma;
    font-weight: bold;
    font-size: 24px;
    color: #b80700;
    position: absolute;
    width: 200px;
    right: 10px;
    bottom: 60px;
    text-align: center;
}

.certificate .section .cert-price .curr {
    font-size: 24px;
}

.curr {
    display: inline-block;
    vertical-align: middle;
}

.certificate .bow-wrap {
    background: url(../../images/giftcertificate/bow-bg.png) repeat-y scroll 80px top;
    position: absolute;
    width: 209px;
    left: 0px;
    top: 0px;
    height: 100%;
    -webkit-print-color-adjust: exact;
}

.certificate .heighter {
    min-height: 60px;
    float: right;
}

.certificate .bow {
    background: url(../../images/giftcertificate/bow.png) no-repeat;
    height: 293px;
    -webkit-print-color-adjust: exact;
}

.block-certificate {
    cursor: pointer;
    font-size: $font-size-medium;
    padding: 0 10px 10px 0;
    margin-bottom: 10px;
}

.block-certificate:after {
    background: transparent none;
}

.block-certificate .block-certificate-txt {
    background: #3d597a;
    padding: 10px 15px;
    color: #fff;
    text-transform: uppercase;
}

.block-certificate img.certificate-img {
    display: block;
    width: 100%;
}


.gift-sertificate-block {
    .breadcrumbs {
        padding-left: 0;
        padding-right: 0;
    }
}
